'use client';

import { fetchBlogs } from '@/actions/blog/fetch-blogs';
import { IBlog } from '@/definition/blog';
import { useEffect, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/navigation';

const LatestBlogsFeature = () => {
  const [blogs, setBlogs] = useState<IBlog[]>([]);
  const router = useRouter();

  useEffect(() => {
    const getBlogs = async () => {
      try {
        const { data } = await fetchBlogs();
        if (data) {
          setBlogs(data.slice(-3));
        }
      } catch (error) {
        console.error(error);
      }
    };
    getBlogs();
  }, []);

  const handleBlogClick = (blogId: string) => {
    router.push(`/blog/${blogId}`);
  };

  return (
    blogs.length > 0 && (
      <div className="my-5">
        <div className="container">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-800 inline-block relative after:absolute after:w-4/6 after:h-1 after:left-0 after:right-0 after:-bottom-4 after:mx-auto after:bg-pink-400 after:rounded-full">
              LATEST BLOGS
            </h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-16 max-lg:max-w-3xl max-md:max-w-md mx-auto">
            {blogs.map((blog) => (
              <div
                key={blog.id}
                onClick={() => handleBlogClick(blog.id)}
                className="bg-white cursor-pointer rounded overflow-hidden shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative top-0 hover:-top-2 transition-all duration-300"
              >
                <div className="relative h-60 w-full">
                  <Image
                    src={`${process.env.NODE_ENV !== 'production' ? 'http://' : 'https://'}${blog.thumbnail}`}
                    alt={blog.title}
                    className="object-cover aspect-video"
                    fill
                    priority
                  />
                </div>
                <div className="p-6">
                  <div className="flex justify-between items-center gap-2">
                    <span className="text-sm block text-gray-400 mb-2">
                      {new Date(blog.publishedAt).toLocaleDateString()} |{' '}
                      {blog.user.firstName} {blog.user.lastName}
                    </span>
                    <span className="text-sm block text-gray-400 mb-2">
                      {blog.views} views
                    </span>
                  </div>
                  <h3 className="text-xl font-bold text-gray-800">
                    {blog.title}
                  </h3>
                  <hr className="my-4" />
                  <p className="text-gray-400 text-sm">{blog.excerpt}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  );
};

export default LatestBlogsFeature;
